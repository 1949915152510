@import './assets/fonts/Lato/lato.css';
@import './assets/fonts/JosefinSans/josefinsans.css';
@import './assets/fonts/Poppins/poppins.css';
@import './assets/fonts/Roboto/roboto.css';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

$grid-breakpoints: (
    xxxxxs: 0,
    xxxxs: 320px,
    xxxs: 360px,
    xxs: 480px,
    xs: 568px,
    sm: 667px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1440px
);
$container-max-widths: (
    xxxxxs: 0,
    xxxxs: 320px,
    xxxs: 360px,
    xxs: 480px,
    xs: 568px,
    sm: 667px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1440px,
);

@import '~bootstrap/scss/bootstrap.scss';
@import './assets/styles/mixins.scss';
@import '~react-sidemenu/dist/side-menu.css';
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";

$pe-7s-font-path: "~pe7-icon/dist/fonts" !default;
@import "~pe7-icon/dist/scss/pe-icon-7-stroke";

@function size-fluid($f-min, $f-max, $v-min, $v-max, $units: px) {
    $k: ($f-max - $f-min)/($v-max - $v-min);
    $b: $f-min - $k * $v-min;
    $b: $b + $units;
    @return calc( #{$k} * 100vw + #{$b} );
}

$lato: 'Lato', sans-serif;
$roboto: 'Roboto', sans-serif;
$poppins: 'Poppins', sans-serif;
$josefinsans: 'Josefin Sans', sans-serif;

$white: #fff;
$black: #000;

$primary: #fff200;

html {
    overflow-x: hidden;
}

body {
    background: $black;
    @include media-breakpoint-down(md) {
        padding-top: 80px;
    }
    &.modal-open {
        padding-right: 0px !important;
    }
}

.css-yrspe {
    z-index: 3 !important;
}
.css-1rbq2qy {
    z-index: 3 !important;
}

.scall-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -100;

    img {
        max-height: 100vh;
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        z-index: -100;
    }
    >.shade {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -99;
        height: 100%;
        width: 100%;
        @include background-radial(rgba(0,0,0,0.25), 0%, rgba(0,0,0,0.7), 100%);
    }
    @include media-breakpoint-down(md) {
        background: $black !important;
        top: 80px;
    }
}

.scall-nav {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: transparent !important;
    padding: 10px;
    padding-bottom: 17px;
    z-index: 2;

    @include media-breakpoint-down(md) {
        position: fixed;
        background: $black !important;
    }

    .nav-collapser {
        display: none;

        @include media-breakpoint-down(md) {
            display: block;
        }
    }

    .navbar-image {
        @include media-breakpoint-down(md) {
            width: 56px;
            height: auto;
        }
    }

    .navbar-toggler {
        text-transform: uppercase;
    }

    .nav-social {
        margin: 10px;
        margin-right: 0px;
        color: $white;
        font-family: $roboto;
        font-size: size-fluid(12, 19, 320, 1366);

        @media screen and (min-width: 1366px) {
            font-size: 19px;
        }
        
        >.nav-facebook {
            margin: 0px 10px;
        }

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .collapse-social {
        display: none;
        margin: 10px;
        color: $white;
        font-family: $roboto;
        font-size: size-fluid(12, 19, 320, 1366);

        @media screen and (min-width: 1366px) {
            font-size: 19px;
        }
        
        >.nav-facebook {
            margin: 0px 10px;
        }
        
        @include media-breakpoint-down(md) {
            display: block;
        }
    }

    .navbar-nav {
        font-family: $lato;
        font-size: size-fluid(16, 21, 320, 1366);
        font-weight: bold;

        @media screen and (min-width: 1366px) {
            font-size: 21px;
        }
        
        >.nav-item {
            padding-left: 47px;
            
            >.nav-link {
                color: $white;
                text-transform: uppercase;

                &:hover {
                    color: $primary;
                }

                &.active {
                    color: $primary;
                }
            }
            @include media-breakpoint-down(md) {
                padding-left: 0px;
            }
        }
    }

    &.scrolled {
        position: fixed;
        right: 0;
        left: 0;
        top: 0;
        background: $black !important; 
        
        .nav-social {
            display: none;
        }

        &.awake {
            transition: .3s all ease-out;
        }

        @include media-breakpoint-down(md) {
            background: $black !important; 
        }
    }
}

footer {
    position: relative;
    background: $black;
}

.footer-wraper {
    border-top: 5px solid $black;
    margin-bottom: 30px;
    @media screen and (max-width: 992px) {
        margin-bottom: 65px;
    }
    width: 100%;
    text-align: center;
    height: 10px;

    >.footer-shield {
        position: relative;
        margin-top: -50px;
    }

    >.wraper {
        position: relative;
        top: 0;
        width: 100%;
        height: 100%;
        background: $primary;
        z-index: 1;
    }
}

.footer-content {
    color: $white;
    font-family: $roboto;
    font-size: size-fluid(12, 19, 320, 1366);
    
    @media screen and (min-width: 1366px) {
        font-size: 19px;
    }

    .footer-facebook {
        >span {
            margin-right: 7px;
        }
        >a>img {
            vertical-align: text-top;
            height: size-fluid(12, 19, 320, 1366);
        }
    }

    a {
        color: $white;

        &:hover {
            color: $white;
        }
    }
}

.footer-spacer {
    margin-top: -5px;
    position: absolute;
    left: 0;
    right: 0;
    background: $black;
    max-height: inherit;
    overflow-y: hidden;
}

.not-found {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    * {
        text-align: center;
    }
    .text {
        font-weight: 600;
        color: $primary;
        font-family: $lato;
        padding: 10px;
    }
    
    .message {
        color: $primary;
        font-family: $poppins;
        font-size: size-fluid(24, 36, 320, 1366);
        padding: 10px;
    }

    .button {
        padding: 10px;
        a{
            background: #fff200;
            color: #000;
            padding: 10px 20px;
            font-family: "Lato", sans-serif;
            font-weight: 700;
            text-transform: uppercase;
        }
    }
}

#header {
    >.container {
        padding: 0px 40px;
        line-height: 1;
        font-family: $lato;
        font-weight: 900;
        color: $white;
        text-transform: uppercase;
        font-size: size-fluid(24, 87, 320, 1366);

        @media screen and (min-width: 1366px) {
            font-size: 87px;
        }

        .yellow {
            color: $primary;
        }
        a.btn {
            font-weight: 700;
            padding: 10px 30px;
            border-width: 2px;            
            @include border-radius(1px);
            font-size: size-fluid(12, 21, 320, 1366);

            @media screen and (min-width: 1366px) {
                font-size: 21px;
            }

            &:hover {
                color: $black;
            }
        }
    }
}

.home-about {
    .wraper {
        width: 100%;
        text-align: center;
        height: 0px;
        >.shield {
            position: absolute;
            left: calc(50% - 121px);
            margin-top: -80px;
            
            @include media-breakpoint-down(sm) {
                width: 40%;
                left: 30%;
            }
        }
        >.logo {
            position: absolute;
            left: calc(50% - 36px);
            margin-top: -27.5px;

            @include media-breakpoint-down(sm) {
                width: 15%;
                left: 42.5%;
                margin-top: -10px;
            }
        }
    }
    .content {
        background: $white;
        .container {
            position: relative;
            color: $black;
            font-family: $roboto;
            font-weight: 400;
            font-size: size-fluid(12, 19, 320, 1366);

            @media screen and (min-width: 1366px) {
                font-size: 19px;
            }
            
            h1 {
                text-align: center;
                color: $black;
                font-family: $lato;
                font-weight: 700;
                text-transform: uppercase;
                font-size: size-fluid(18, 48, 320, 1366);

                @media screen and (min-width: 1366px) {
                    font-size: 48px;
                }
            }
            h3 {
                text-align: center;
                color: $black;
                font-family: $lato;
                font-weight: 400;
                text-transform: uppercase;
                font-size: size-fluid(12, 24, 320, 1366);

                @media screen and (min-width: 1366px) {
                    font-size: 24px;
                }
            }

            img {
                position: absolute;
                left: 25%;
                width: 50%;
            }

            .line-wrap {
                height: 100%;
                width: 8px;
                margin: 0px 20px;
                background: $black;
                position: absolute;
                right: 0;
                @include border-radius
            }
        }
        
    }
}

.home-classes {
    background-image: url('./assets/img/classes-background.jpg');
    background-size: 100%;
    background-position: center;

    @include media-breakpoint-down(md) {
        background-image: radial-gradient(rgb(0, 0, 0) 0%, rgb(20, 45, 89) 100%);
    }

    .effect {
        @include background-radial(rgba(0,0,0,0.2), 0%, rgba(0,0,0,0.5), 100%);
    }

    h1 {
        text-align: center;
        color: $white;
        font-family: $lato;
        font-weight: 700;
        text-transform: uppercase;
        font-size: size-fluid(18, 48, 320, 1366);

        @media screen and (min-width: 1366px) {
            font-size: 48px;
        }

        > span {
            color: $primary;
            padding-right: size-fluid(3, 15, 320, 1366);
        }
    }
    p {
        text-align: center;
        color: $white;
        font-family: $roboto;
        font-weight: 400;
        padding: 10px 0;
        font-size: size-fluid(12, 19, 320, 1366);

        @media screen and (min-width: 1366px) {
            font-size: 19px;
        }
    }
    .class-card {
        background: $white;
        > img {
            width: 100%;
            height: 180px;
            object-fit: cover;
        }
        > .class-description {
            color: $black;
            font-family: $lato;
            font-weight: 700;
            font-size: size-fluid(14, 17, 320, 1366);

            @media screen and (min-width: 1366px) {
                font-size: 17px;
            }

            padding: 5px 15px;
            text-transform: uppercase;
        }
    }
    a.btn {
        @include border-radius(1px);
        border-color: $primary;
        color: $primary;
        font-family: $lato;
        font-weight: 700;
        text-transform: uppercase;
        padding: 10px 20px;
        font-size: size-fluid(12, 19, 320, 1366);

        @media screen and (min-width: 1366px) {
            font-size: 19px;
        }

        margin: auto;

        &:hover {
            color: $black;
            background-color: $primary;
        }
    }
}

.gallery {
    background: $white;

    h1 {
        text-align: center;
        color: $black;
        font-family: $lato;
        font-weight: 700;
        text-transform: uppercase;
        font-size: size-fluid(18, 48, 320, 1366);
        
        @media screen and (min-width: 1366px) {
            font-size: 48px;
        }
    }

    p {
        text-align: center;
        color: $black;
        font-family: $roboto;
        font-weight: 400;
        padding: 10px 0;
        font-size: size-fluid(12, 19, 320, 1366);
        
        @media screen and (min-width: 1366px) {
            font-size: 19px;
        }
    }
    .carousel-item {
        padding: 0 5px;

        .carousel-image {
            width: 100%;
            height: 200px;
            object-fit: cover;
            cursor: pointer;
        }
    }
    .slick-next:before {
        content: "\e684";
    }
    .slick-prev:before {
        content: "\e686";
    }
    .slick-prev, .slick-next {
        transform: translate(0, -35%) scale(5.5);
        &::before {
            color: $black !important;
            font-family: "Pe-icon-7-stroke";
        }
    }
    .slick-dots li {
        display: none;
    }
}

.first {
    margin-top: 125px;
    
    @include media-breakpoint-down(md) {
        margin-top: 0px;
    }
}
.no-wrap {
    white-space: nowrap;
    @include media-breakpoint-down(md) {
        display: inline-block;
        margin: 3px;
    }
}

.about {
    background: $white;
    color: $black;
    font-family: $roboto;
    font-weight: 400;
    font-size: size-fluid(12, 19, 320, 1366);
    
    @media screen and (min-width: 1366px) {
        font-size: 19px;
    }
    
    .line-wrap {
        height: 100%;
        width: 8px;
        background: $primary;
        left: 0;
        @include border-radius
    }
    img {
        width: 100%;
    }
    h1 {
        color: $black;
        font-family: $lato;
        font-weight: 700;
        text-transform: uppercase;
        font-size: size-fluid(18, 48, 320, 1366);

        @media screen and (min-width: 1366px) {
            font-size: 48px;
        }

        width: 100%;
    }
    h3 {
        color: $black;
        font-family: $lato;
        font-weight: 400;
        text-transform: uppercase;
        font-size: size-fluid(12, 24, 320, 1366);

        @media screen and (min-width: 1366px) {
            font-size: 24px;
        }

        width: 100%;
    }
    
    .about-image {
        width: 100%;
        @media screen and (min-width: 768px) {
            width: 50%;
        }
        &.left {
            float: left;
            padding-bottom: 15px;
            padding-top: 10px;
            @media screen and (min-width: 768px) {
                padding-right: 20px;
            }
        }
        &.right {
            float: right;
            padding-bottom: 15px;
            padding-top: 10px;
            @media screen and (min-width: 768px) {
                padding-left: 20px;
            }
        }
    }
}

.loading {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: $black;
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;

    div {
        position: absolute;
        top: 27px;
        width: 11px;
        height: 11px;
        @include border-radius(50%);
        background: $primary;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);

        &:nth-child(1) {
            left: 6px;
            animation: lds-ellipsis1 0.6s infinite;
        }
        &:nth-child(2) {
            left: 6px;
            animation: lds-ellipsis2 0.6s infinite;
        }
        &:nth-child(3) {
            left: 26px;
            animation: lds-ellipsis2 0.6s infinite;
        }
        &:nth-child(4) {
            left: 45px;
            animation: lds-ellipsis3 0.6s infinite;
        }

        @keyframes lds-ellipsis1 {
            0% {
                transform: scale(0);
            }
            100% {
                transform: scale(1);
            }
        }      
        @keyframes lds-ellipsis2 {
            0% {
                transform: translate(0, 0);
            }
            100% {
                transform: translate(19px, 0);
            }
        }
        @keyframes lds-ellipsis3 {
            0% {
                transform: scale(1);
            }
            100% {
                transform: scale(0);
            }
        }
    }
}

.classes-small {
    background-image: url('./assets/img/classes-background.jpg');
    background-size: 100% auto;
    background-position: center;

    .effect {
        @include background-radial(rgba(0,0,0,0.2), 0%, rgba(0,0,0,0.6), 100%);
    }

    h1 {
        color: $primary;
        font-family: $lato;
        font-weight: 700;
        text-transform: uppercase;
        font-size: size-fluid(18, 48, 320, 1366);

        @media screen and (min-width: 1366px) {
            font-size: 48px;
        }
    }
    p {
        color: $white;
        font-family: $lato;
        font-weight: 400;
        text-transform: uppercase;
        font-size: size-fluid(10, 28, 320, 1366);

        @media screen and (min-width: 1366px) {
            font-size: 27px;
        }
    }
    
    a.btn {
        @include border-radius(1px);
        border-color: $primary;
        color: $primary;
        font-family: $lato;
        font-weight: 700;
        text-transform: uppercase;
        padding: 10px 20px;
        font-size: size-fluid(12, 19, 320, 1366);

        @media screen and (min-width: 1366px) {
            font-size: 19px;
        }

        margin: auto;

        &:hover {
            color: $black;
            background-color: $primary;
        }
    }
}

.contact-map {
    background: $white;
    width: 100%;
    height: 576px;
    max-height: 75vh;
}

.contact-form {
    background: $white;
    padding-bottom: 20px;
    h1 {
        text-align: center;
        color: $black;
        font-family: $lato;
        font-weight: 700;
        text-transform: uppercase;
        font-size: size-fluid(18, 48, 320, 1366);
        
        @media screen and (min-width: 1366px) {
            font-size: 48px;
        }
    }
    h3 {
        color: $black;
        font-family: $lato;
        font-weight: 700;
        text-transform: uppercase;
        font-size: size-fluid(12, 24, 320, 1366);
        
        @media screen and (min-width: 1366px) {
            font-size: 24px;
        }
    }
    p {
        color: $black;
        font-family: $roboto;
        font-weight: 400;
        font-size: size-fluid(12, 16, 320, 1366);
        
        @media screen and (min-width: 1366px) {
            font-size: 16px;
        }
    }
    a.btn {
        background: $primary;
        color: $black;
        padding: 10px 20px;
        font-family: $lato;
        font-weight: 700;
        text-transform: uppercase;
        font-size: size-fluid(12, 16, 320, 1366);
        
        @media screen and (min-width: 1366px) {
            font-size: 16px;
        }
    }
    .wrap-horizontal {
        background: $primary;
        height: 8px;
        width: 100px;
        margin: 20px auto;
        @include border-radius
    }
    .wrap-vertical {
        position: absolute;
        margin-left: size-fluid(-35, -43, 480, 1600);
        background: $primary;
        height: 100%;
        width: 8px;
        @include border-radius
    }
    .alert {
        width: 100%;
        margin: 0 15px;
    }
    .form-group {
        label {
            font-family: $lato;
            padding-top: 0.375rem;
        }
        .form-control {
            @include border-radius(0px);
            border-color: $black;
        }
        input {
            border-right: none;
            border-left: none;
            border-top: none;
            color: $black;
            &::placeholder {
                color: $black;
                font-family: $lato;
            }
        }
        textarea {
            min-height: 110px;
        }
        >.btn {
            background: $primary;
            color: $black;
            width: 100%;
            padding: 10px 30px;
            font-family: $lato;
            font-weight: 700;
            text-transform: uppercase;
            font-size: size-fluid(12, 16, 320, 1366);
            
            @media screen and (min-width: 1366px) {
                font-size: 16px;
            }
        }
    }
    .contact-recaptcha {
        div {
            margin: auto;

            @include media-breakpoint-down(xxxxs) {
                transform: scale(0.95);
                -webkit-transform: scale(0.95);
                transform-origin: 0 0;
                -webkit-transform-origin: 0 0;
            }
        }
    }
}

.events {
    background: $white;
    position: relative;

    h1 {
        text-align: center;
        color: $black;
        font-family: $lato;
        font-weight: 700;
        text-transform: uppercase;
        font-size: size-fluid(18, 48, 320, 1366);
        
        @media screen and (min-width: 1366px) {
            font-size: 48px;
        }
    }
    .event-date {
        position: absolute;
        top: 0;
        left: 0;
        background: $primary;
        padding: 5px 15px;
        color: $black;
        text-align: center;
        line-height: 1;
        font-family: $lato;

        -webkit-box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.62);
        -moz-box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.62);
        box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.62);

        .day {
            font-weight: bold;
            font-size: size-fluid(16, 42, 320, 1366);

        }
        .month {
            text-transform: uppercase;
            font-size: size-fluid(12, 28, 320, 1366);
        }

        &::after {
            position: absolute;
            left: 0;
            content: '';
            margin-top: 5px;
            width: 15px;
            height: 15px;
            display: block;
            background-image: url('./assets/img/events-triangle.png');
        }
    }
    .event-image {
        width: 100%;
        height: 270px;
        >img {
            width: 100%;
            height: 100%;
            background:radial-gradient(rgb(0, 0, 0) 0%, rgb(77, 77, 77) 100%);
            @include border-radius(2px);
            object-fit: cover;
        }
    }
    a {
        color: $white;
        &:hover {
            color: $white;
        }
    }
    .event-name {
        position: relative;
        background: $black;
        padding: 10px;
        font-family: $lato;
        font-weight: 700;
        color: $white;
        text-transform: uppercase;
        width: 100%;
        padding-right: 27px;
        font-size: size-fluid(12, 18, 320, 1366);
        @media screen and (min-width: 1366px) {
            font-size: 18px;
        }

        .name {
            display: block;
            padding-right: 15px;
        }
    }
    .details-arrow {
        position: absolute;
        right: 10px;
        top: 10px;
        line-height: 0;
        cursor: pointer;
        svg {
            padding: 0;
            margin: 0;
            width: size-fluid(18, 27, 320, 1366);
            height: size-fluid(18, 27, 320, 1366);
            @media screen and (min-width: 1366px) {
                width: 27px;
                height: 27px;
            }
        }
    }
}

.team {
    background: $white;
    h1 {
        text-align: center;
        color: $black;
        font-family: $lato;
        font-weight: 700;
        text-transform: uppercase;
        font-size: size-fluid(18, 48, 320, 1366);
        
        @media screen and (min-width: 1366px) {
            font-size: 48px;
        }
    }
    p {
        text-align: center;
        color: $black;
        font-family: $roboto;
        font-weight: 400;
        font-size: size-fluid(12, 19, 320, 1366);
        margin: 0;
        
        @media screen and (min-width: 1366px) {
            font-size: 19px;
        }
    }
    .wrap-horizontal {
        background: $primary;
        height: 8px;
        width: 100px;
        margin: 20px auto;
        background: $primary;
        @include border-radius
    }
    .member {
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
        .member-name {
            background: $black;
            padding: 10px;
            font-family: $lato;
            font-weight: 700;
            color: $white;
            text-transform: uppercase;
            width: 100%;
            font-size: size-fluid(12, 18, 320, 1366);
            @media screen and (min-width: 1366px) {
                font-size: 18px;
            }
            text-decoration: inherit;
        }
    }

    .member-desc {
        p {
            text-align: left;
        }
    }
    
    .member-photo {
        width: 100%;
        object-fit: cover;
    }
    .details-arrow {
        padding: 0;
        margin: 0;
        float: right;
        line-height: 0;
        svg {
            padding: 0;
            margin: 0;
            width: size-fluid(18, 27, 320, 1366);
            height: size-fluid(18, 27, 320, 1366);
            @media screen and (min-width: 1366px) {
                width: 27px;
                height: 27px;
            }
        }
    }
}

.classes {
    background: $white;
    padding: 0;

    .Side-menu-custom {
        padding: 0;
        background: $white;
        width: 100% !important;
        max-width: none;

        @include media-breakpoint-down(md) {
            text-align: center;
        }
        .divider {
            font-family: $lato;
            font-weight: bold;
            font-size: size-fluid(24, 36, 320, 1366);
            text-transform: capitalize;
            height: auto;
            width: 100%;
            padding: 0;

            border-bottom: 5px solid $primary;
            padding-bottom: 5px;
            margin-bottom: 15px;

            @media screen and (min-width: 1366px) {
                font-size: 36px;
            }
        }
    
        .item {
            font-family: $lato;
            font-weight: bold;
            height: auto;
            width: 100%;
            
            &.active {
                background: $primary;
                &:after {
                    position: absolute;
                    z-index: 1;
                    content: '';
                    margin-top: size-fluid(-28, -37, 320, 1366);
                    right: 0;
                    background-image: url('./assets/img/triangle-left.svg');
                    background-size: cover;
                    height: size-fluid(22, 31, 320, 1366);
                    width: size-fluid(22, 31, 320, 1366);
                    @media screen and (min-width: 1366px) {
                        margin-top: -43px;
                        height: 43px;
                        width: 43px;
                    }
                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }
            }

            .item-title {
                padding: 5px 20px;
                height: auto;
                .item-label {
                    font-size: size-fluid(16, 22, 320, 1366) !important;
                    @media screen and (min-width: 1366px) {
                        font-size: 22px !important;
                    }
                }
            }
        }
    }
    .shade-left {
        @include media-breakpoint-up(lg) {
            -webkit-box-shadow: -5px 0px 5px -5px rgba(0,0,0,0.2);
            -moz-box-shadow: -5px 0px 5px -5px rgba(0,0,0,0.2);
            box-shadow: -5px 0px 5px -5px rgba(0,0,0,0.2);
        }
    }
    .class-content {
        font-family: $roboto;
        font-size: size-fluid(12, 19, 320, 1366);
        @media screen and (min-width: 1366px) {
            font-size: 19px;
        }
        h1 {
            font-size: size-fluid(18, 48, 320, 1366);
            color: $black;
            font-family: $lato;
            font-weight: 700;
            text-transform: uppercase;
            @media screen and (min-width: 1366px) {
                font-size: 48px;
            }
        }
        h3 {
            color: $black;
            font-family: $lato;
            font-weight: 400;
            text-transform: uppercase;
            font-size: size-fluid(12, 24, 320, 1366);

            @media screen and (min-width: 1366px) {
                font-size: 24px;
            }
        }
        img {
            width: 100%;
        }
        .class-image {
            width: 100%;
            float: right;
            padding-bottom: 15px;
            @media screen and (min-width: 768px) {
                width: 50%;
                padding-left: 15px;
            }
        }
    }
}